'use strict';

import "@babel/polyfill";
import { ua } from './view/_ua';
import { header } from './view/_header';
import { accordion } from './view/_accordion';
import { toTop } from './view/_toTop';
//import { height } from './view/_height';
//import { scroll } from './view/_scroll';
import { swiper } from './view/_swiper';
import { smoothScroll } from './view/_smoothScroll';
//import { contentMenu } from './view/_contentMenu';
import { form } from './view/_form';
import { parallax } from './view/_parallax';
