// const _accordion = $('.js-accordion')
// _accordion.click(function(){
// 	$(this).parents('.l-navigation__content').toggleClass('is-open')
// })

//アコーディオン
$(function(){
	$(".js-accordion-trigger").on("click", function() {
	$(this).toggleClass('open')
	$(this).next('.js-accordion-target').slideToggle(150); //faqページのアコーディオン
	// $('.p-about .js-accordion-target').slideToggle(150); //aboutページの企業リストアコーディオン
	// const moreText = document.getElementById("moreText");
	// moreText.innerHTML = "閉じる";
	});
	});


//アバウトページの企業リストアコーディオン (sp時は2つ目のリストを消しておき、クリックしたら表示、テキストも変える)
	$(function() {
		// $('.p-aboutCompany__moreBtn').prev().hide();
		$('.p-aboutCompany__moreBtn').on("click", function(e) {
			e.preventDefault();
			if ($(this).prev().is(':hidden')) {
				$(this).prev().slideDown();
				$(this).find('.p-aboutCompany__accordionText').text('閉じる').addClass('boxopen').removeClass('boxclose');
				$('.p-aboutCompany__moreBtn').addClass('boxopen').removeClass('boxclose');
			} else {
				$(this).prev().slideUp();
				$(this).find('.p-aboutCompany__accordionText').text('さらに表示').removeClass('boxopen').addClass('boxclose');
				$('.p-aboutCompany__moreBtn').removeClass('boxopen').addClass('boxclose');
			}
		});
	});


