//TOPへ戻るボタン(クリック時の挙動)
$(function(){
  $('.c-pageTop').on('click', function () {
    $('body,html').animate({
    scrollTop: 0
    }, 400);
    return false;
  });
});

//TOPへ戻るボタン(下スクロール時は非表示、上スクロールしたら表示する)
let startPos = 0;
let winScrollTop = 0;
const toTop = $('#js-pageTop');
$(window).on('scroll',function(){
  winScrollTop = $(this).scrollTop();
  if (winScrollTop >= startPos && winScrollTop > 0) { // ios用に&& winScrollTop > 0を追加してる
		$(toTop).removeClass('show');
  } else {
    $(toTop).removeClass('hide');
		$(toTop).addClass('show');
  }
  startPos = winScrollTop;
});

