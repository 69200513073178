//PC headerが途中から出てきて固定される 
$(function() {
  let header = $('.l-header.-top');
  //scrollしたとき
  $(window).on('scroll', function () {
  //現在の位置が500pxより大きいかつ、クラスfixedが付与されていない（false）時
  if($(this).scrollTop() > 500 && header.hasClass('fixed') == false) {
  //headerの高さ分上に設定(上から降りてくるようにするため)
  header.css({top: '-80px'});
  //クラスfixedを付与
  header.addClass('fixed');
  //位置を0に設定。0.3秒かけてアニメーションでにゅるっと降りてくる
  header.animate({top: 0},300);
}

  //現在の位置が500px以下かつ、クラスfixedが付与されている時にfixedを外す
  else if($(this).scrollTop() <= 500 && header.hasClass('fixed') == true){
  header.removeClass('fixed');
}

//場合によってフロートナビゲーションの残像が残ることがあったので追記。
  else if($(this).scrollTop() <= 500) { 
    header.removeClass("fixed");
  }
});
});



const _menuBtn = $('#js-hamburger')
const _header = $('#js-header')
const _navigation = $('#js-navigation')

// const _subMenuBtn = $('.has-child')
_menuBtn.click(function(){
	$('html').toggleClass('is-fixed')
	_menuBtn.toggleClass('is-open')
	_navigation.toggleClass('is-show')

})
