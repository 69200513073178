// import top_action from './top';
import Swiper from 'swiper/bundle';

//about部分 swiper
let AboutSwiper = null;
const mediaQuery = window.matchMedia('(max-width: 1024px)');
const checkBreakpoint = (e) => {
	// メディアクエリにマッチしたらスライダーを初期化
	if (e.matches) {
		initSwiper();
	} else if (AboutSwiper) {
		// メディアクエリにマッチせず、スライダーが初期化済みだったらスライダーを解除
		AboutSwiper.destroy(false, true);
	}
}
const initSwiper = () => {
	AboutSwiper = new Swiper('.p-homeAbout__swiper', {
		slidesPerView: 1,
		// spaceBetween: 16,
		watchSlidesProgress: true,
		loop: true,
		loopAdditionalSlides: 1,
		// speed: 1000,
		// autoplay: {
		// 	speed: 1000,
		// 	delay: 4000,
		// 	disableOnInteraction: false,
		// },
		grabCursor: true,
		pagination: {
			el: '.p-home__about .swiper-pagination',
			clickable: true,
		},
		navigation: {
			nextEl: '.p-homeAbout-button-next',
			prevEl: '.p-homeAbout-button-prev',
		},
		breakpoints: {
			1024: {
				slidesPerView: 3,
			}
		},
	});
};
mediaQuery.addListener(checkBreakpoint); // ブレークポイント通過時に実行
checkBreakpoint(mediaQuery); // ロード時に初回実行


//support部分 swiper
// const SupportSwiper = new Swiper('.p-homeSupport__swiper', {
// 	// autoplay: {
// 	// 	delay: 4000,
// 	// 	speed: 900,
// 	// },
// 	speed: 900,
//   slidesPerView: 'auto',
// 	loop: true,
//   // spaceBetween: 16,
//   // grabCursor: true,
//   pagination: {
//     el: '.p-home__support .swiper-pagination',
//     clickable: true,
//   },
//   navigation: {
//     nextEl: '.p-homeSupport-button-next',
//     prevEl: '.p-homeSupport-button-prev',
//   },
//   // breakpoints: {
//   //   1025: {
// 	// 		slidesPerView: 'auto',
//   //   }
//   // },
// });




//voice部分 swiper
const VoiceSwiper = new Swiper('.p-homeVoice__swiper', {
	// autoplay: {
	// 	delay: 4000,
	// 	speed: 8000,
	// },
  slidesPerView: 'auto',
	// loop: true,
  spaceBetween: 80,
  // grabCursor: true,
  // pagination: {
  //   el: '.p-home__voice .swiper-pagination',
	// 	type: "bullets", //ドット
  //   // clickable: true,
  // },
  navigation: {
    nextEl: '.p-homeVoice-button-next',
    prevEl: '.p-homeVoice-button-prev',
  },
  // breakpoints: { //タブレット以上はプログレスバー表示
  //   769: {
	// 		pagination: {
	// 			el: '.p-home__voice .swiper-pagination',
	// 			type: "progressbar", // プログレスバー
	// 			// clickable: true,
	// 		},
  //   }
  // },
});

//support部分 swiper
setTimeout(function () {
	var supportSwiper = new Swiper ('.p-homeSupport__swiper', {
	slidesPerView: '1',
	// autoHeight:true,
	// simulateTouch: true,
	autoplay: {
		 delay: 6000,
		 disableOnInteraction: false,
	 },
	loop: true,
	// effect: 'fade',
	//fadeEffect: {
	//    crossFade: true
	//  },
	speed: 1000,
	preloadImages: false,
		lazy: {
			loadPrevNext: true,
		},		
		navigation: {
			nextEl: '.p-homeSupport-button-next',
			prevEl: '.p-homeSupport-button-prev',
			},
	pagination: {
			el: '.timer__pagination',
			clickable: true,
			type: 'bullets',
		bulletActiveClass: 'is_active',
		bulletClass: 'num',
			// 個別にクラスをつけることができる
			renderBullet: (index, className) => {
			 return '<p class="num-item-' + (Number(index) + 1) + ' ' + className + '" data-id="' + Number(index)+ '"><svg class="timer" width="10" height="10"><circle cx="10" cy="10" r="9.5"/></svg></p>';
			},
		},on: {
		init: function () {
		 jQuery('.timer__pagination').addClass('init');
		//  setTimeout(() => {
		// 			jQuery('.mv-text').addClass('is_view fast');
		// 		}, 1800);
		 setTimeout(() => {
					jQuery('.swiper-slide-active').addClass('out');
				}, 5000);
			},
			slideChange: () => {
			jQuery('.timer__pagination').removeClass('init');
			// jQuery('.mv-text').removeClass('is_view fast');
			jQuery('.swiper-slide-active').removeClass('out');
			},
		// // スライド（次または前）へのアニメーションの開始後にイベント発生
			slideChangeTransitionEnd: function (result) {
		// setTimeout(() => {
		// 		jQuery('.mv-text').addClass('is_view');
		// 		}, 1800);
		setTimeout(() => {
					jQuery('.swiper-slide-active').addClass('out');
				}, 5000);
			},
			
		}
	});
		
	}, 1000);	

	//サービスエリアのswiper
	// var supportSwiper = new Swiper ('.p-homeSupport__swiper', {
	// 	// デフォルトの設定
	// 	// slidesPerView: 1.425,
	// 	slidesPerView: '1',
	// 	// autoHeight:true,
	// 	// simulateTouch: true,
	// 	spaceBetween:0,
	// 	loop:true,
	// 	navigation: {
	// 		nextEl: '.swiper-button-next',
	// 		prevEl: '.swiper-button-prev',
	// 		},
	// 	pagination: {
	// 		el: '.swiper-pagination',
	// 		type: 'progressbar',
	// 		},
		
	// 	// レスポンシブブレーポイント（画面幅による設定）
	// 	// breakpoints: {
	// 	// // 画面幅が415px 以上の場合（window width >= 769px）
	// 	// 	415: {
	// 	// 		slidesPerView: 2.5
	// 	// 	},
	// 	// 	// 画面幅が769px 以上の場合（window width >= 769px）
	// 	// 	769: {
	// 	// 		slidesPerView: 1.9
	// 	// 	},
	// 	// 	// 画面幅が 1281px 以上の場合（window width >= 1281px）
	// 	// 	1281: {
	// 	// 		slidesPerView: 2.5
	// 	// 	}
	// 	// },
	// 	on: {
	// 			slideChange: function slideChange() {

	// 			// //cap
	// 			// jQuery('.prof-box .cap').removeClass('is-show');
				
	// 			//pagination
	// 			jQuery('.timer__pagination .num').removeClass('is-show');
				
	// //			var nowIndex = mySwiper.realIndex + 1;
	// 				realIndex = this.realIndex + 1;
	// 			var nowIndexClass = 'now-' + realIndex;
	// 			jQuery('.timer__pagination').removeClass(nowIndexClass);
	// 			//pagination
	// 			jQuery('.pagination__current .swiper-item-' + realIndex).addClass('is-show');
	// 			//jQuery('.ex__pagination').addClass(nowIndexClass);
					
	// 			//cap
	// 			// jQuery('.prof-box .swiper-item-' + realIndex).addClass('is-show');
					
	// 			if(nowIndex == 4){
	// //		 		jQuery('.ex-button-next').removeClass('is-show');
	// //				jQuery('.ex-button-next').addClass('disabled');
	// 				jQuery('.ex-button-next').on('click', function () {
	// 				  mySwiper.slideTo(0);
	// 				});
					
	// 	 		}else {
	// 	 			jQuery('.ex-button-next').addClass('is-show');
	// 				jQuery('.ex-button-next').removeClass('disabled');
	// 			}
	// 			}
				
	// 	}	
	// });


// let mySwiper = null;
 
// const checkBreakpoint = (e) => {
//   // メディアクエリにマッチしたらスライダーを初期化
//   if (e.matches) {
//     initSwiper();
//   } else if (mySwiper) {
//     // メディアクエリにマッチせず、スライダーが初期化済みだったらスライダーを解除
//     mySwiper.destroy(false, true);
//   }
// }




