

// var scroll = new SmoothScroll('a[href*="#"]', {
//     speed:1000,
//     speedAsDuration:true,
// });

//アンカーリンクの設定。スクロール時、header分がかぶらないように調整
$('a[href^="#"]').click(function(){
	let speed = 500;
	let href= $(this).attr("href");
	let target = $(href == "#" || href == "" ? 'html' : href);
	let position = target.offset().top - $('#js-header').height();
	$("html, body").animate({scrollTop:position}, speed, "swing");
	return false;
});
